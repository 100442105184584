import { FC, useEffect, useState } from "react";
import { Space } from "../../../../components/Space";
import { Text } from '@consta/uikit/Text';
import { format, parseISO } from "date-fns";
import { MasterRequestStep } from "../types";
import ruLocale from 'date-fns/locale/ru';
import { Button } from "@consta/uikit/Button";
import { Modal } from '@consta/uikit/Modal';
import { useCompleteWorkMutation, useGetRequestDetailsQuery, useStartWorkMutation } from "../services";
import toast from 'react-hot-toast';
import { RequestStateLabel } from "../../home/components/RequestStateLabel";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";


interface Props {
    step: MasterRequestStep,
}

export const StepDescription: FC<Props> = ({ step }) => {
    const request = useSelector((state: RootState) => state.master.selectedRequest)!

    const [startWork, { isError: startWorkError, isSuccess: startWorkIsSuccess }] = useStartWorkMutation()
    const [completeWork, { isError: completeWorkError, isSuccess: completeWorkIsSuccess }] = useCompleteWorkMutation()
    const { refetch } = useGetRequestDetailsQuery(request.id, { skip: !startWorkIsSuccess && !completeWorkIsSuccess })


    const [isModalStartWorkOpen, setModalStartWorkOpen] = useState(false)
    const [isModalFinishWorkOpen, setModalFinishWorkOpen] = useState(false);

    useEffect(() => {
        if (startWorkError) {
            toast.error('Произошла ошибка. Не удалось начать выполнение работ')
        }
        if (completeWorkError) {
            toast.error('Произошла ошибка. Не удалось завершить выполнение работ')
        }
    }, [startWorkError, completeWorkError])

    useEffect(() => {
        if (startWorkIsSuccess || completeWorkIsSuccess) {
            refetch()
        }
    }, [startWorkIsSuccess, completeWorkIsSuccess, refetch])

    return <>
        <Space
            direction="vertical"
            size="2xs"
        >
            <RequestStateLabel state={step.status}/>
            {/* <Space size="xs">
                <Text view="secondary" weight="regular" size="s">№ {step.number}</Text>
                <RequestStateLabel state={step.status} />
            </Space> */}

            <Text view="primary" weight="bold" size="m">{step.stepTitle}</Text>
            <Text view="secondary" weight="regular" size="s">{step.wellPadGroupTitle} / {step.wellPadTitle}</Text>
            <Text view="secondary" size="s">{format(parseISO(step.dateTimeOf), "d MMM HH:mm", { locale: ruLocale })} - {format(parseISO(step.dateTimeTo), "d MMM HH:mm", { locale: ruLocale })}</Text>
            <div style={{ height: 10 }} />
        </Space>

        <Modal
            isOpen={isModalStartWorkOpen}
            hasOverlay
            onClickOutside={() => setModalStartWorkOpen(false)}
            onEsc={() => setModalStartWorkOpen(false)}
            style={{
                zIndex: 100,
            }}
        >   <div style={{ padding: 'var(--space-s)' }}>
                <Text as="p" size="l" view="primary" lineHeight="m" weight="semibold">{step.stepTitle}</Text>
                <Text as="p" size="m" view="secondary" lineHeight="m">Подтвердите начало выполнения шага</Text>
                <Space>
                    <div>
                        <Button
                            size="m"
                            view="primary"
                            label="Подтвердить"
                            width="default"
                            onClick={() => {
                                setModalStartWorkOpen(false)
                                startWork({ stepId: step.id })
                            }}
                        />
                    </div>
                    <div>
                        <Button
                            size="m"
                            view="ghost"
                            label="Отмена"
                            width="default"
                            onClick={() => setModalStartWorkOpen(false)}
                        />
                    </div>
                </Space>
            </div>
        </Modal>

        <Modal
            isOpen={isModalFinishWorkOpen}
            hasOverlay
            onClickOutside={() => setModalFinishWorkOpen(false)}
            onEsc={() => setModalFinishWorkOpen(false)}
            style={{
                zIndex: 100,
            }}
        >   <div style={{ padding: 'var(--space-s)' }}>
                <Text as="p" size="l" view="primary" lineHeight="m" weight="semibold">{step.stepTitle}</Text>
                <Text as="p" size="m" view="secondary" lineHeight="m">Подтвердите завершение выполнения шага</Text>
                <Space>
                    <div>
                        <Button
                            size="m"
                            view="primary"
                            label="Подтвердить"
                            width="default"
                            onClick={() => {
                                setModalFinishWorkOpen(false)
                                completeWork({ stepId: step.id })
                            }}
                        />
                    </div>
                    <div>
                        <Button
                            size="m"
                            view="ghost"
                            label="Отмена"
                            width="default"
                            onClick={() => setModalFinishWorkOpen(false)}
                        />
                    </div>
                </Space>
            </div>
        </Modal>
    </>
}
import { EquipmentState } from "../../../components/EquipmentStateLabel/types";
import { IssueRequest, IssueRequestType, SlotType } from "../types";
import { DriverErrorState } from "./components/DriverErrorState";
import { DriverStateLoadingMaterials } from "./components/DriverStateLoadingMaterials";
import { DriverStateOfflineRequest } from "./components/DriverStateOfflineRequest";
import { DriverStateOnRoute } from "./components/DriverStateOnRoute";
import { DriverStateOperatingSlot } from "./components/DriverStateOperatingSlot";
import { DriverStateStartLoadMaterials } from "./components/DriverStateStartLoadMaterials";
import { DriverStateStartMovement } from "./components/DriverStateStartMovement";


export function mapDriverState(equipmentState: EquipmentState | undefined, issueRequest: IssueRequest | undefined, slotType: SlotType | undefined) {

    if (issueRequest) {
        switch (issueRequest.type) {
            case IssueRequestType.GO_OUT_OF_SERVICE:
                return <DriverStateOfflineRequest issueRequest={issueRequest} />
            default: {
                return <DriverErrorState />
            }
        }
    }

    switch (slotType) {
        case SlotType.ResourceOperationStepSlot:
        case SlotType.ResourceSupplyUnloadingSlot:
            switch (equipmentState) {
                case EquipmentState.STATE_WAITING:
                    return <DriverStateOperatingSlot />
                case EquipmentState.STATE_WORK_IN_PROGRESS:
                    return <DriverStateOperatingSlot />
                default:
                    return <DriverErrorState />
            }
        case SlotType.ResourceReturnToBaseLocationSlot:
            return <></>
        case SlotType.ResourceSupplyBaseLoadingSlot:
            switch (equipmentState) {
                case EquipmentState.STATE_WAITING:
                    return <DriverStateStartLoadMaterials />
                case EquipmentState.STATE_LOADING:
                    return <DriverStateLoadingMaterials />
                default:
                    return <DriverErrorState />
            }
        case SlotType.ResourceTransportationSlot:
            switch (equipmentState) {
                case EquipmentState.STATE_WAITING:
                    return <DriverStateStartMovement />
                case EquipmentState.STATE_ON_ROUTE:
                    return <DriverStateOnRoute />
                case EquipmentState.STATE_ARRIVAL_UNCONFIRMED:
                    return <DriverStateOperatingSlot />
                default:
                    return <DriverErrorState />
            }
    }
}
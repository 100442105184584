import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../store"
import { useGetRequestDetailsQuery, useGetTelemetryMutation } from "./services"
import { StepEquipments } from "./components/StepEquipments"
import { ErrorDataPlaceholder } from "../../../components/ErrorDataPlaceholder"
import { StepDescription } from "./components/StepDescription"
import { Card } from '@consta/uikit/Card';
import { useEffect } from "react"
import { updateEquipmentPositions } from "../../../components/Map/slice"
import { useInterval } from "react-use"
import { StepDetailsSkeleton } from "./components/StepDetailsSkeleton"
import { getDataRefreshPeriod } from "../../../utils/settings"
import { Space } from "../../../components/Space"
import { RequestStateLabel } from "../home/components/RequestStateLabel"
import { Text } from '@consta/uikit/Text';


export const RequestDetails = () => {
    const request = useSelector((state: RootState) => state.master.selectedRequest)!
    const dispatch = useDispatch()

    const { data, error, isLoading, refetch } = useGetRequestDetailsQuery(request.id)
    const [getTelemetry, { data: equipmentTelemetry }] = useGetTelemetryMutation()

    useEffect(() => {
        if (data && !equipmentTelemetry) {
            const resorcesIds = data.steps.flatMap(element => element.resourceIds)
            if (resorcesIds.length > 0) {
                getTelemetry({ equipmentIds: resorcesIds })
            }
        }
    }, [data, getTelemetry])

    useEffect(() => {
        if (equipmentTelemetry?.items) {
            const positions = equipmentTelemetry.items.map(element => {
                return {
                    geoY: element.position?.lat,
                    geoX: element.position?.lng,
                    direction: 0,
                    regNumber: element.regNumber,
                    equipmentId: element.equipmentId,
                }
            })
            dispatch(updateEquipmentPositions(positions))
        }
    }, [equipmentTelemetry, dispatch])

    useInterval(
        () => {
            refetch()
            if (data) {
                const resorcesIds = data.steps.flatMap(element => element.resourceIds)
                if (resorcesIds.length > 0) {
                    getTelemetry({ equipmentIds: resorcesIds })
                }
            }
        },
        getDataRefreshPeriod() * 1000,
    )

    return <div style={{ marginTop: 30 }}>
        {isLoading && <div style={{ textAlign: 'center', }}><StepDetailsSkeleton /></div>}

        {error && !isLoading && <ErrorDataPlaceholder title="Произошла ошибка" description="Не удалось загрузить данные о шаге" />}

        {!isLoading && !error &&
            <Space
                direction="vertical"
                size="xs"
                style={{
                    marginLeft: 'var(--space-l)',
                    marginRight: 'var(--space-l)',
                    marginBottom: 'var(--space-l)',
                }}
            >
                <Space size="xs">
                    <Text view="secondary" weight="regular" size="s">№ {request.reqNum}</Text>
                    <RequestStateLabel state={request.state} />
                </Space>
                <Text view="primary" weight="bold" size="m">{request.name}</Text>
            </Space>
        }

        {!isLoading && !error && data?.steps.map((step, index) => {
            return <Card
                key={index}
                border
                verticalSpace="s"
                horizontalSpace="s"
                form="round"
                style={{
                    marginTop: 2,
                    marginBottom: index === data.steps.length - 1 ? 2 : 12,
                    marginLeft: 'var(--space-s)',
                    marginRight: 'var(--space-s)',
                }}
            >
                <StepDescription step={step} />
                <div style={{ height: 10 }} />
                <StepEquipments
                    resourceIds={step.resourceIds}
                    resources={data.resources}
                    stepState={step.status}
                    stepId={step.id}
                />
            </Card>
        })}
    </div>
}